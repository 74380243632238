f<template>
    <!-- Create Cycle Modal -->
    <b-modal id="create-cycle-modal" title="Create a Cycle" hide-footer :hide-header-close="busy" no-close-on-backdrop
        centered>
        <validation-observer ref="formObserver">
            <b-form @submit.prevent="onSubmit">
                <b-form-group label-size="lg" label="Cycle Title" label-for="title-input">
                    <validation-provider name="Cycle Title" rules="required">
                        <template #default="{ errors }">
                            <b-form-input id="title-input" v-model="title" :state="errors.length > 0 ? false : null"
                                placeholder="Type a Title" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </template>
                    </validation-provider>
                </b-form-group>
                <b-form-group label-size="lg" label="Cycle Deadline" label-for="deadline-input">
                    <validation-provider name="Cycle Deadline" rules="required">
                        <template #default="{ errors }">
                            <b-form-datepicker menu-class="w-100" :min="minCalendarDate" id="deadline-picker"
                                :state="!!deadline" v-model="deadline" class="mb-2" value-as-date />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </template>
                    </validation-provider>
                </b-form-group>
                <div class="d-flex justify-content-center p-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" ref="submit"
                        type="submit" :disabled="busy">
                        Start Cycle
                    </b-button>
                </div>
            </b-form>
        </validation-observer>
        <b-overlay :show="busy" :opacity=0.9 no-wrap @shown="onShown" @hidden="onHidden">
            <template v-slot:overlay>
                <div v-if="!processing" ref="dialog" tabindex="-1" role="dialog" aria-modal="false"
                    aria-labelledby="form-confirm-label" class="text-center p-0">
                    <p class="h3 mb-50"><strong id="form-confirm-label">Are you sure?</strong></p>
                    <p v-if="title && deadline" class="mb-2 text-summary">You are creating <b>{{ title }}</b> ending on
                        <b>
                            {{
                                deadline.toLocaleDateString("en-US", options).split('at')[0]
                            }}
                        </b>.
                    </p>
                    <div class="text-center">
                        <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mr-3"
                            @click="onCancel">
                            Cancel
                        </b-button>
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" @click="onOK">
                            OK
                        </b-button>
                    </div>
                </div>
            </template>
        </b-overlay>
    </b-modal>

</template>

<script>
import { BModal, BForm, BButton, BOverlay, BFormGroup, BFormInput, VBModal, BFormDatepicker } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import router from '@/router'

extend('required', {
    ...required,
    message: 'This field is required'
});

export default {
    components: {
        BModal,
        BForm,
        BButton,
        BOverlay,
        BFormGroup,
        BFormInput,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BFormDatepicker,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    computed: {
    },
    data() {
        return {
            options: {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                // timeZone: "UTC",
                timeZoneName: "short"
            },
            title: null,
            minCalendarDate: new Date(+new Date() + 86400000).toISOString().split('T')[0],
            deadline: null,
            processing: false,
            busy: false,
            required,
        }
    },
    methods: {
        show() {
            this.$bvModal.show('create-cycle-modal')
        },
        hide() {
            this.$bvModal.hide('create-cycle-modal')
        },
        resetModal() {
            this.title = null
            this.deadline = null
        },
        onShown() {
            this.$refs.dialog.focus()
        },
        onHidden() {
            this.$refs.submit.focus()
        },
        async onSubmit() {
            const isValid = await this.$refs.formObserver.validate()
            if (!isValid) {
                return
            }
            this.processing = false
            this.busy = true
        },
        onCancel() {
            this.busy = false
        },
        onOK() {
            const data = {
                title: this.title,
                deadline: this.deadline.setHours(13, 0, 0),
            }
            this.processing = true

            this.$http.post(`/cycles`, data)
                .then(response => {
                    this.$nextTick(() => {
                        this.busy = this.processing = false;
                    });
                    this.hide();
                    this.success();
                    this.resetModal();
                    router.go()
                })
                .catch(error => {
                    const errorMessage = (error.response && error.response.data && error.response.data.error) || 'An unknown error occurred';
                    console.error('Error:', errorMessage);
                    this.error(errorMessage);
                    this.$nextTick(() => {
                        this.busy = this.processing = false;
                    });
                    this.hide();
                    this.resetModal();
                    router.go()
                });
        },
        success() {
            this.$swal({
                title: `Cycle Created!`,
                text: `${this.title} was successfully created.`,
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
        error(errorMessage) {
            this.$swal({
                title: 'Error!',
                text: errorMessage,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
    /* background: #dfe5fb;
    border: none; */
    color: #b7b7b9;
    /* text-transform: lowercase;
    font-variant: small-caps; */
}

/* .style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: #394066;
} */

.text-summary {
    color: rgb(66, 66, 66);
    width: 140%;
    margin: 0 -20%;
    padding: 10px;
}
</style>