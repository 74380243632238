<template>
  <b-card no-body class="card-developer-meetup">
    <b-card-header class="d-flex justify-content-center">
      <h1>Start a Review Cycle</h1>
    </b-card-header>
    <b-card-body class="my-2">
      <!-- metting header -->
      <!--/ metting header -->

      <b-card-text> By clicking on <span class="text-success"><i> + Start Cycle</i></span>, PRS users will be able to view, fill and submit surveys. </b-card-text>
      <b-card-text>
        
        Please make sure that <b>questionnaires, users</b> and <b>levels</b> are <b>correctly set up</b> beforehand.
      
      </b-card-text>

    </b-card-body>
    <b-button activev-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success"
      @click="$emit('createCycleModal')">
      <feather-icon icon="PlusIcon" class="mr-50" size="16" />
      <span class="align-middle">Start Cycle</span>
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BRow, BCol, BButton, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BRow,
    BCol,
    BButton,
    VueApexCharts,
    BCardHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: 0, // -10
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
      },
    }
  }
}
</script>
