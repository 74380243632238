<template>
  <b-sidebar id="edit-cycle-sidebar" :visible="isEditCycleSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
    shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-cycle-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Cycle
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- Cycle Title -->
          <validation-provider #default="validationContext" name="Title" rules="required">
            <b-form-group label="Title*" label-for="title">
              <b-form-input id="title" v-model="cycleData.title" :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Deadline -->
          <validation-provider #default="validationContext" name="Deadline" rules="required">
            <b-form-group label="Deadline*" label-for="deadline">
              <b-form-datepicker id="deadline-picker" :state="!!cycleData.deadline" :min="minCalendarDate"
                v-model="cycleData.deadline" class="mb-2" value-as-date />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Save
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditCycleSidebarActive',
    event: 'update:is-edit-cycle-sidebar-active',
  },
  props: {
    isEditCycleSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      required: true,
    }
  },
  data() {
    return {
      options: {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        // timeZone: "UTC",
        timeZoneName: "short"
      },
      minCalendarDate: new Date(+new Date() + 86400000).toISOString().split('T')[0],
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    // Fetch advisors, levels, and locations on mount
    const blankCycleData = ref({
      title: '',
      deadline: '',
    })

    const cycleData = ref({ ...blankCycleData.value })

    const resetCycleData = () => {
      cycleData.value = { ...blankCycleData.value }
    }

    // Watch for changes to the sidebar active state
    watch(() => props.isEditCycleSidebarActive, (newVal) => {
      if (newVal && props.item) {
        // Populate the form fields when the sidebar is opened
        cycleData.value.title = props.item.title
        cycleData.value.deadline = props.item.deadline
      }
    }, { immediate: true })

    const { proxy } = getCurrentInstance()

    const onSubmit = () => {
      cycleData.value.deadline = new Date(new Date(cycleData.value.deadline).setHours(13, 0, 0))
      proxy.$http.put(`/cycles/${props.item._id}`, cycleData.value)
        .then((res) => {
          emit('update:is-edit-cycle-sidebar-active', false)
          emit('refetch-data', res.data)
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Cycle Saved Successfully!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch((err) => {
          const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
          proxy.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              text: errorMessage,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCycleData)

    return {
      cycleData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-cycle-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
