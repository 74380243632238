<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Survey Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated Just Now
      </b-card-text>
    </b-card-header>

    <b-card-body v-if="data && data.length">
      <vue-apex-charts type="bar" height="100" :options="surveyBarChart.chartOptions" :series="surveyBarChart.series" />

      <!-- chart info -->
      <div class="pt-25">
        <div v-for="(data, index) in chartData.listData" :key="index" class="d-flex justify-content-between"
          :class="index === chartData.listData.length - 1 ? '' : 'mb-1'">
          <div class="series-info">
            <feather-icon :icon="data.icon" size="16" class="mr-50" :class="data.iconColor" />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span><b>{{ data.result }}</b></span>
        </div>
      </div>
    </b-card-body>
    <b-card-body v-else>
      <p>No survey statistics available.</p>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      surveyBarChart: {
        series: [],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 250,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '50%',
            },
          },
          colors: [$themeColors.success, $themeColors.danger],
          xaxis: {
            categories: ['Surveys'],
            max: 100,
          },
          tooltip: {
            y: {
              formatter: val => `${val}%`,
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
          },
        },
      },
      chartData: {
        listData: [],
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (newData.length) {
          this.initializeChartData(newData)
        } else {
        }
      }
    }
  },
  methods: {
    initializeChartData(newData) {
      const totalSurveys = newData.find(item => item.subtitle === 'Total Surveys').title
      const submittedSurveysCount = newData.find(item => item.subtitle === 'Submitted Surveys').title
      const unsubmittedSurveysCount = newData.find(item => item.subtitle === 'Unsubmitted Surveys').title

      // Calculate percentages
      const submittedPercent = ((submittedSurveysCount / totalSurveys) * 100).toFixed(0)
      const unsubmittedPercent = ((unsubmittedSurveysCount / totalSurveys) * 100).toFixed(0)

      // Set bar chart series
      this.surveyBarChart.series = [
        {
          name: 'Submitted Surveys',
          data: [submittedPercent],
        },
        {
          name: 'Unsubmitted Surveys',
          data: [unsubmittedPercent],
        },
      ]

      // Set list data for chart success
      this.chartData.listData = [
        {
          icon: 'CheckSquareIcon',
          text: 'Submitted Surveys',
          result: submittedSurveysCount,
          iconColor: 'text-success',
        },
        {
          icon: 'EditIcon',
          text: 'Unsubmitted Surveys',
          result: unsubmittedSurveysCount,
          iconColor: 'text-danger',
        },
      ]
    },
  },
}
</script>
