<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>User Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated Just Now
      </b-card-text>
    </b-card-header>

    <b-card-body v-if="data && data.length">
      <b-row>
        <b-col md="6">
          <!-- Pie Chart for active / inactive users -->
          <vue-apex-charts type="pie" height="275" class="mt-2 mb-1" :options="activeUsersPie.chartOptions"
            :series="activeUsersPie.series" />

          <!-- list group -->
          <div class="pt-25 w-75 mx-auto">
            <div v-for="(data, index) in chartData.listData" :key="index" class="d-flex justify-content-between"
              :class="index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'">
              <div class="series-info">
                <feather-icon :icon="data.icon" size="16" class="mr-50" :class="data.iconColor" />
                <span class="font-weight-bolder">{{ data.text }}</span>
              </div>
              <span><b>{{ data.result }}</b></span>
            </div>
          </div>
        </b-col>
        <!-- <b-col md="1"></b-col> -->
        <b-col md="6">
          <!-- apex chart -->
          <vue-apex-charts type="radialBar" height="310" :options="userStatisticsRadialBar.chartOptions"
            :series="userStatisticsRadialBar.series" />

          <!-- chart info -->
          <div v-for="(data, key, index) in chartInfo" :key="key" class="d-flex justify-content-between"
            :class="index === Object.keys(chartInfo).length - 1 ? '' : 'mb-1'">
            <div class="series-info d-flex align-items-center">
              <feather-icon icon="CircleIcon" size="16"
                :class="key === 'notSubmitted' ? 'text-primary' : key === 'notNominated' ? 'text-warning' : 'text-danger'" />
              <span class="font-weight-bold ml-75">{{ keyText(key) }}</span>
            </div>
            <span><b>{{ data }}</b></span>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body v-else>
      <p>No user statistics available.</p>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText,
    BRow,
    BCol,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartInfo: {},
      userStatisticsRadialBar: {
        series: [],
        chartOptions: {
          labels: ['Did not submit', 'Not nominated', 'Did not nominate'],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '20%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
                total: {
                  show: true,
                  label: 'Total',
                  fontSize: '1.286rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  formatter: () => {
                    return this.totalUsers
                  },
                },
              },
            },
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      },
      activeUsersPie: {
        series: [],
        chartOptions: {
          labels: ['Active Users', 'Inactive Users'],
          colors: ['#14A44D', '#DC4C64'],
          plotOptions: {
            pie: {
              expandOnClick: true,
              donut: {
                size: '50%',
              },
            },
          },
          legend: {
            show: false,
            position: 'bottom',
          },
          dataLabels: {
            enabled: true,
            formatter: (val) => `${val.toFixed(0)}%`,
          },
        },
      },
      totalUsers: 0,
      chartData: {
        listData: [],
      },
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        if (newData.length) {
          try {
            this.initializeChartData();
            this.initializePieChartData();
          } catch (error) {
            console.error('Error initializing data:', error);
          }
        } else {
          console.warn('No valid data received:', newData);
        }
      }
    }
  },
  methods: {
    initializeChartData() {
      try {
        // Calculate total users
        this.totalUsers = this.data[0].title;
        this.usersWithSurveys = this.data[5].title;

        // Calculate percentages for the chart
        const unsubmittedUsersPercent = ((this.data[2].title / this.usersWithSurveys) * 100).toFixed(0);
        const unnominatedUsersPercent = ((this.data[3].title / this.totalUsers) * 100).toFixed(0);
        const unnominatingUsersPercent = ((this.data[4].title / this.totalUsers) * 100).toFixed(0);
        
        // Set chart series
        this.userStatisticsRadialBar.series = [
          unsubmittedUsersPercent,
          unnominatedUsersPercent,
          unnominatingUsersPercent,
        ];

        // Set chart info
        this.chartInfo = {
          notSubmitted: this.data[2].title,
          notNominated: this.data[3].title,
          notNominating: this.data[4].title,
        };
      } catch (error) {
        console.error('Error in initializeChartData:', error);
      }
    },
    initializePieChartData() {
      try {
        const activeUsersCount = this.data[1].title;
        const inactiveUsersCount = this.totalUsers - activeUsersCount;
        
        // Calculate percentages for the pie chart
        const activeUsersPercent = ((activeUsersCount / this.totalUsers) * 100).toFixed(0);
        const inactiveUsersPercent = ((inactiveUsersCount / this.totalUsers) * 100).toFixed(0);

        // Set pie chart series
        this.activeUsersPie.series = [
          activeUsersCount,
          inactiveUsersCount,
        ];

        // Set list data for pie chart info
        this.chartData.listData = [
          {
            icon: 'CheckCircleIcon',
            text: 'Active Users',
            result: activeUsersCount,
            iconColor: 'text-success',
          },
          {
            icon: 'XCircleIcon',
            text: 'Inactive Users',
            result: inactiveUsersCount,
            iconColor: 'text-danger',
          },
        ];
      } catch (error) {
        console.error('Error in initializePieChartData:', error);
      }
    },
    keyText(key) {
      const keyTextMap = {
        notSubmitted: "Users who have a pending survey(s) to submit",
        notNominated: "Users who have not been nominated for any survey",
        notNominating: "Users who have not nominated anyone for a survey",
      };
      return keyTextMap[key];
    },
  },
};
</script>
